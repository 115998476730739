import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"


import formatBoysen from "../../../lib/formatBoysen"

import * as styles from "./header.module.css"

const surfaceinfo = require('../../content/data/listsurface.json');

const PaintHeader = ({ surfacecode, productnamemarkup }) => {
	const [headerheight, setHeaderheight] = useState(0);

	useEffect(() => {
		if (typeof window === 'undefined') return;
		function handleResizeEffect() {
			setTimeout(function() {
				setHeaderheight(document.getElementsByName("headersizer")[0].getBoundingClientRect().height);
			}, 100);

		}
		handleResizeEffect();
		window.addEventListener('resize', handleResizeEffect)
		return _ => {
			window.removeEventListener('resize', handleResizeEffect)
		}
	}, [])

	if (surfacecode.length < 4 || productnamemarkup.length < 1) {
		return <></>
	}

	var tmpidx = 0;
	var typeid = "";
	var surfacetype = "";
	var surfacedetail = "";
	const surfacecondition = surfacecode.substring(3,4).toUpperCase()==="N"?"New":"Repaint";
	const surfaceprefix = surfacecode.toUpperCase().substring(0,3);

	while (tmpidx < surfaceinfo.listsurfaceobj.length) {
		if (surfaceinfo.listsurfaceobj[tmpidx].code === surfaceprefix) {
			typeid = surfaceinfo.listsurfaceobj[tmpidx].typeid;
			surfacedetail = surfaceinfo.listsurfaceobj[tmpidx].name;
			break;
		}
		tmpidx++;
	}

	if (surfacedetail.length < 1) {
		return <></>
	}
	tmpidx = 0;
	while (tmpidx < surfaceinfo.listsurfacetypeobj.length) {
		if (surfaceinfo.listsurfacetypeobj[tmpidx].id === typeid) {
			surfacetype = surfaceinfo.listsurfacetypeobj[tmpidx].name;
			break;
		}
		tmpidx++;
	}
	if (surfacetype.length < 1) {
		return <></>
	}

	function fixheadername(markup)
	{
		markup = formatBoysen.trimstr(markup);
		const plaintext = formatBoysen.stripNameTags(markup);
		const midpoint = (plaintext.length)>>1;
		var finalidx = 0;
		var tmpidx = plaintext.indexOf(" ", midpoint);
		var tmpidxbefore = plaintext.substring(0, midpoint).lastIndexOf(" ");

		if (plaintext.charAt(midpoint) === " ") {
			finalidx = midpoint;
		} else if (tmpidx > 0 && tmpidxbefore > 0) {
			// whichever is closer to the center
			if (midpoint - tmpidxbefore >  tmpidx - midpoint) {
				finalidx = tmpidx;
			} else {
				finalidx = tmpidxbefore;
			}
		} else if (tmpidx > 0) {
			finalidx = tmpidx;
		} else {
			finalidx = tmpidxbefore;
		}

		//console.log(plaintext,midpoint, tmpidx,tmpidxbefore);
		if (finalidx <= 0) {
			// nothing to break
			return markup;
		}
		// get word if possible
		tmpidx = plaintext.indexOf(" ", finalidx+1);
		if (tmpidx <= 0) {
			// get next 5 characters
			tmpidx = finalidx+5;
		}
		//console.log(finalidx, tmpidx,plaintext, plaintext.substring(finalidx, tmpidx));
		tmpidx = markup.indexOf(plaintext.substring(finalidx, tmpidx));
		return markup.substring(0, tmpidx)+"<br/>"+markup.substring(tmpidx+1);
	}
	function renderdivider(tmptypeclass)
	{
		return <span className={styles.holderarrowinfo+" "+tmptypeclass} >&nbsp;<br/>&nbsp;</span>
	}

	function renderheader(headerheight, surfacetype, surfacedetail, surfacecondition, productnamemarkup, frozen)
	{
		return (
			<div className={frozen?styles.holder+" "+styles.holderfrozen:styles.holder+" "+styles.holderhidden} style={frozen?{"top":headerheight}:{}} >
				<div className={styles.holdertext+" color-text-primary"}>
					<div className={styles.holdertextborder+" "+styles.holdertextborderleft}>
						<span className={styles.holdertextinfo+" "+styles.holderbodystart} dangerouslySetInnerHTML={{__html:fixheadername(surfacetype)}} />
						{renderdivider(styles.holderarrowmiddle)}
						<span className={styles.holdertextinfo+" "+styles.holderbodymiddle} dangerouslySetInnerHTML={{__html:fixheadername(surfacedetail)}} />
						{renderdivider(styles.holderarrowmiddle)}
						<span className={styles.holdertextinfo+" "+styles.holderbodymiddle} dangerouslySetInnerHTML={{__html:fixheadername(surfacecondition)}} />
					</div>
					<div className={styles.holdertextproductname}>
						<div className={styles.holdertextborder+" "+styles.holdertextborderfilled}>
							{renderdivider(styles.holderarrowprefilled)}
							<span className={styles.holdertextinfo+" "+styles.holderbodyfilled} dangerouslySetInnerHTML={{__html: fixheadername(productnamemarkup) }} />
						</div>
						{renderdivider(styles.holderarrowfilled)}
					</div>
				</div>
			</div>
		)
	}

	return (
		<>
			{renderheader(headerheight, surfacetype, surfacedetail, surfacecondition, productnamemarkup, false)}
			{renderheader(headerheight, surfacetype, surfacedetail, surfacecondition, productnamemarkup, true)}
		</>
	)
}


PaintHeader.propTypes = {
	surfacecode: PropTypes.string,
	productnamemarkup: PropTypes.string
};

PaintHeader.defaultProps = {
	surfacecode: ``,
	productnamemarkup: ``
};

export default PaintHeader;

